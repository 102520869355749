<template>
  <div class="tellMePageRoot">
    <div class="tellMeRoot">
      <div class="desc">
        <h2>联系方式</h2>
        <div>地点：河南省郑州市金水区豫博大厦东塔909</div>
        <div>联系电话：0371-86541037 13103832189</div>
        <div>QQ：921306770</div>
        <div>邮箱：921306770@qq.com</div>
        <div class="code">
          <div>微信：</div>
          <div class="textCenter">
            <el-image style="width: 100px; height: 100px;padding:2px;border:1px solid #484747" :src="require('@assets/img/Code/wx_tell_study.png')" fit="fill" :preview-src-list="[require('@assets/img/Code/wx_tell_study.png')]"></el-image>
            <div>学习咨询(商混)</div>
          </div>
          <div class="textCenter">
            <el-image style="width: 100px; height: 100px;padding:2px;border:1px solid #484747" :src="require('@assets/img/Code/study_test_code.jpg')" fit="fill" :preview-src-list="[require('@assets/img/Code/study_test_code.jpg')]"></el-image>
            <div>学习咨询(检测)</div>
          </div>
          <div class="textCenter">
            <el-image style="width: 100px; height: 100px;padding:2px;border:1px solid #484747" :src="require('@assets/img/Code/wx_tell.png')" fit="fill" :preview-src-list="[require('@assets/img/Code/wx_code.png')]"></el-image>
            <div>技术咨询</div>
          </div>
        </div>
      </div>
      <div class="tel">
        <h2>请留下您的宝贵意见</h2>
        <el-form ref="formRef" :model="form" label-position="left" label-width="40px">
          <el-form-item label="姓名">
            <el-input v-model="form.name" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="form.email" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="电话">
            <el-input v-model="form.tel" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="内容">
            <el-input type="textarea" :rows="4" placeholder="" v-model="form.desc"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button @click="sub" type="info">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="map">
      <div id="map" style="height:400px"></div>
    </div>
  </div>
</template>
<script lang="ts">
import { ref, defineComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";
import { ElMessage } from "element-plus";
import T from 'T'
export default defineComponent({
  name: "tellMe",
  setup(){
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    let formRef = ref(null)
    const position = [113.6774,34.78726]
    let mapApi = null
    let form = ref({
      name: "",
      email: "",
      tel: "",
      desc: "",
    });

    function init() {
      console.log(T)
      const zoom = 14;
      mapApi = new T.Map(document.getElementById('map'), {
        projection: 'EPSG:4326'
      });
      console.log(mapApi)
      mapApi.centerAndZoom(new T.LngLat(...position), zoom)
      mapApi.addOverLay(new T.Marker(new T.LngLat(...position)))
      var content = `<h3 style="color:#1989fa">河南省豫建建筑工程技术服务中心</h3>
        <div>电话:0371-86541037 13103832189</div>
        <div style="width: max-content;">地址:河南省-郑州市-金水区 豫博大厦东塔909</div>`
      mapApi.addOverLay(new T.Label({
        text:content,
        offset: new T.Point(-150, 30),
        position:new T.LngLat(...position)
      }))
    }

    function sub() {
      ElMessage.success("提交成功,感谢您的意见！");
      form.value = {
        name: "",
        email: "",
        tel: "",
        desc: "",
      };
    }

    onMounted(() => {
      init();
    });
    return {
      form,
      sub
    }
  }
})
</script>
<style lang="less" scoped>
.tellMePageRoot {
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  .tellMeRoot {
    display: flex;
    .desc {
      flex: 1;
      > div {
        margin-bottom: 20px;
      }
      .code {
        display: flex;
        .textCenter {
          text-align: center;
          margin-right: 20px;
        }
      }
    }
    .tel {
      flex: 1;
    }
  }
  .map {
    margin: 20px 0 50px 0;
  }
}

@media (max-width: 600px) {
  .tellMePageRoot {
    padding: 5px;
    max-width: 100%;
    .tellMeRoot {
      display: unset;
    }
  }
}
</style>