
import { ref, defineComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";
import { ElMessage } from "element-plus";
import T from 'T'
export default defineComponent({
  name: "tellMe",
  setup(){
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    let formRef = ref(null)
    const position = [113.6774,34.78726]
    let mapApi = null
    let form = ref({
      name: "",
      email: "",
      tel: "",
      desc: "",
    });

    function init() {
      console.log(T)
      const zoom = 14;
      mapApi = new T.Map(document.getElementById('map'), {
        projection: 'EPSG:4326'
      });
      console.log(mapApi)
      mapApi.centerAndZoom(new T.LngLat(...position), zoom)
      mapApi.addOverLay(new T.Marker(new T.LngLat(...position)))
      var content = `<h3 style="color:#1989fa">河南省豫建建筑工程技术服务中心</h3>
        <div>电话:0371-86541037 13103832189</div>
        <div style="width: max-content;">地址:河南省-郑州市-金水区 豫博大厦东塔909</div>`
      mapApi.addOverLay(new T.Label({
        text:content,
        offset: new T.Point(-150, 30),
        position:new T.LngLat(...position)
      }))
    }

    function sub() {
      ElMessage.success("提交成功,感谢您的意见！");
      form.value = {
        name: "",
        email: "",
        tel: "",
        desc: "",
      };
    }

    onMounted(() => {
      init();
    });
    return {
      form,
      sub
    }
  }
})
